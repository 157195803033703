<template>
  <div
    class="button-cta-container"
    :class="[sizeClass, { disabled: isDisabled }]"
  >
    <BaseButton
      v-bind="$attrs"
      :to="to"
      :disabled="isDisabled"
      class="button-cta"
    >
      {{ label }}
    </BaseButton>
    <div v-if="caption.length" class="caption button-cta__caption">
      {{ caption }}
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import BaseButton from "./BaseButton.vue";

export default {
  components: {
    BaseButton,
  },
  props: {
    to: {
      type: [String, Object],
      required: false,
    },
    size: {
      type: String,
      default: "medium",
    },
    caption: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const sizeClass = computed(() => {
      const { size } = props;
      if (size === "large") return "button-cta-container--large";
      if (size === "small") return "button-cta-container--small";
      if (size === "x-small") return "button-cta-container--x-small";

      return "";
    });

    return {
      sizeClass,
    };
  },
};
</script>

<style scoped>
/* CTA BUTTONS */
.button-cta-container {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.button-cta {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 15px;
  min-width: 240px;
  width: max-content;
  height: var(--button-height);

  color: var(--button-cta-font-color);
  font-family: var(--default-font-family);
  font-size: var(--default-font-size);
  font-weight: var(--button-font-weight);
  text-align: center;

  background: var(--button-cta-bg-color);
  border: var(--button-cta-border);
  box-shadow: var(--button-cta-shadow);
  box-sizing: border-box;
  border-radius: var(--button-border-radius);
}

.button-cta:hover {
  box-shadow: var(--button-cta-hover-shadow);
  cursor: pointer;
}

.button-cta-container--large .button-cta {
  width: var(--button-large-width);
  font-size: var(--button-large-font-size);
  height: var(--button-large-height);
}

.button-cta-container--small .button-cta {
  height: var(--button-small-height);
  min-width: var(--button-width);
  font-size: var(--button-small-font-size);
}

.button-cta-container--x-small .button-cta {
  height: var(--button-x-small-height);
  min-width: var(--button-width);
  font-size: var(--button-x-small-font-size);
}

.button-cta__caption {
  margin-top: 20px;
  display: block;
  width: 215px;
  text-align: center;
  font: var(--button-cta-default-caption-font);
  opacity: var(--button-caption-opacity);
}
.button-cta-container--large .button-cta__caption {
  width: 215px;
  font: var(--button-cta-large-caption-font);
}
.button-cta-container.disabled {
  pointer-events: none;
}
</style>
